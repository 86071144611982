<template>
    <div>
        <div class="pt-8 bg-white">
            <div class="my-2">
                <div class="relative">
                    <img src="@/assets/map.jpg" alt="Mappa dell'Ospedale" class="w-full h-auto">
                    <div v-for="poi in pois" :key="poi.id" :style="{ top: poi.top, left: poi.left }" class="absolute">
                        <button @click="selectPoi(poi)"
                            :class="['rounded-full p-2 shadow-lg', selectedPoi && selectedPoi.id === poi.id ? 'bg-red-600' : 'bg-blue-600']"
                            class="text-white">
                            <span class="material-icons">location_on</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedPoi" class="bg-white p-4 mt-4">
            <h2 class="text-xl font-bold mb-2">{{ selectedPoi.name }}</h2>
            <p class="mb-4">{{ selectedPoi.description }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">place</span>Posizione: {{
                selectedPoi.location }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">schedule</span>Orari: {{
                selectedPoi.hours }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">phone</span>Telefono: {{
                selectedPoi.phone }}</p>
            <div class="flex justify-end">
                <button @click="togglePanorama"
                    class="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 mr-2">
                    Panorama
                </button>
                <!--
                <button @click="clearSelection" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                    Chiudi
                </button>
                -->
            </div>


            <div v-if="panoramaOpen" class="fixed inset-0 flex items-center justify-center z-50">
                <div class="fixed inset-0 bg-black opacity-50" @click="togglePanorama"></div>
                <div class="bg-white rounded-lg shadow-lg z-50 h-2/3" style="width: 95vw;">
                    <div class="flex justify-end p-2">
                        <button @click="togglePanorama" class="text-black">
                            <span class="material-icons">close</span>
                        </button>
                    </div>
                    <div id="panorama" class="relative w-full h-full"></div>
                </div>
            </div>


        </div>
        <div v-else class="text-gray-600 p-4 mt-4">Seleziona un punto di interesse sulla mappa per vedere i dettagli.
        </div>

    </div>
</template>


<script>
/* global pannellum */

export default {
    data() {
        return {
            id: null,
            panoramaOpen: false,
            panorama: {
                type: "equirectangular",
                panorama: "",
                autoLoad: true,
                orientationOnByDefault: true
            },
            selectedPoi: null,
            pois: [
                {
                    id: 1,
                    name: 'Reparto Oncologia',
                    description: 'Il reparto di oncologia si occupa delle malattie tumorali.',
                    location: '3º Piano',
                    hours: '09:00 - 17:00',
                    phone: '0123-456791',
                    top: '10%',
                    left: '25%',
                    panorama: require('@/assets/panorama1.jpg')
                },
                {
                    id: 2,
                    name: 'Reparto Cardiologia',
                    description: 'Il reparto di cardiologia si occupa delle malattie del cuore.',
                    location: '2º Piano',
                    hours: '10:00 - 18:00',
                    phone: '0123-456790',
                    top: '25%',
                    left: '45%',
                    panorama: require('@/assets/panorama2.jpg')
                },
                {
                    id: 3,
                    name: 'Ingresso Principale',
                    description: 'L\'entrata principale dell\'ospedale.',
                    location: 'Piano Terra',
                    hours: '24 ore su 24',
                    phone: '0123-456789',
                    top: '52%',
                    left: '65%',
                    panorama: require('@/assets/panorama3.jpg')
                },

                {
                    id: 4,
                    name: 'Reparto Pediatria',
                    description: 'Il reparto di pediatria si occupa delle malattie dei bambini.',
                    location: '1º Piano',
                    hours: '08:00 - 16:00',
                    phone: '0123-456792',
                    top: '60%',
                    left: '25%',
                    panorama: require('@/assets/panorama4.jpg')
                },
                {
                    id: 5,
                    name: 'Farmacia',
                    description: 'La farmacia interna dell\'ospedale.',
                    location: 'Piano Terra, Ala Ovest',
                    hours: '09:00 - 18:00',
                    phone: '0123-456793',
                    top: '70%',
                    left: '45%',
                    panorama: require('@/assets/panorama5.jpg')
                },
            ],
        };
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.selectPoiById(this.id);
        }
    },
    mounted() {
        if (typeof pannellum === 'undefined') {
            console.error('Pannellum is not loaded.');
        }
    },
    methods: {
        selectPoiById(id) {
            const poi = this.pois.find(p => p.id === Number(id));
            if (poi) {
                this.selectPoi(poi);
            } else {
                console.error('POI not found');
            }
        },
        selectPoi(poi) {
            this.selectedPoi = poi;
            this.panorama.panorama = poi.panorama;
            this.panoramaOpen = false;
        },
        clearSelection() {
            this.selectedPoi = null;
        },
        togglePanorama() {
            if (this.panoramaOpen) {
                this.panoramaOpen = false;
            } else {
                this.panoramaOpen = true;
                this.$nextTick(() => {
                    if (typeof pannellum !== 'undefined') {
                        try {
                            pannellum.viewer('panorama', this.panorama);
                        } catch (e) {
                            console.log(e.message);
                        }
                    } else {
                        console.error('Pannellum is not defined.');
                    }
                });
            }
        },
    },
};
</script>


<style>
html {
    background-color: white;
}

span.material-icons.icon-small {
    font-size: 20px !important;
}
</style>