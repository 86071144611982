<template>
    <div class="flex justify-center items-center h-screen">
        <iframe class="w-full h-full max-w-4xl" src="https://routar.andnowfit.com/mindar/markers.html" 
            frameborder="0" 
            allow="camera; microphone; gyroscope; accelerometer; fullscreen; xr-spatial-tracking"
            allowfullscreen
            permissionspolicy="interest-cohort=(), xr-spatial-tracking=(self)">
        
        </iframe>
    </div>
</template>

<script>
export default {
    name: 'IframeView',
}
</script>

<style scoped>
/* Aggiungi eventuali stili personalizzati qui */
</style>