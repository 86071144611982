<template>
    <div class="p-4 mb-4 border rounded-lg shadow-md">
        <h2 class="text-blue-600 hover:underline flex items-center text-xl font-semibold mb-4">
            <span class="material-icons mr-2">{{ service.icon }}</span>
            {{ service.name }}
        </h2>

        <p class="text-gray-700 mb-4">{{ service.description }}</p>
        <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">schedule</span>Orari: {{
            service.hours }}</p>
        <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">place</span>Posizione: {{
            service.location }}</p>
        <p class="flex items-center mb-2 "><span class="material-icons icon-small mr-2">phone</span>Telefono: {{
            service.phone }}</p>
    </div>
</template>

<script>
export default {
    name: 'InfoCard',
    props: {
        service: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
span.material-icons.icon-small {
    font-size: 20px !important;
}
</style>