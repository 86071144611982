<!-- src/components/HomeView.vue -->
<template>
  <div class="bg-blue-100 flex flex-col" style="min-height: 96dvh;">

    <!-- Hero Section -->
    <section class="flex-grow flex flex-col items-center justify-center bg-blue-100 bg-cover bg-center">
      <img src="@/assets/android-chrome-512x512.png" style="width: 60dvw">
      <div class="bg-blue-600 text-white px-6 py-3 rounded-full text-lg hover:bg-blue-700 transition cursor-pointer mt-8" @click="goToAR">
        Inizia la Navigazione
      </div>
    </section>

    <!-- Main Sections -->
    <section class="bg-blue-100 pb-4 px-4">
      <div class="container mx-auto grid grid-cols-2 gap-4">
        <a class="bg-gray-100 p-4 rounded-lg shadow-lg text-center flex flex-col items-center hover:bg-gray-200 transition"
          @click="goToLocation">
          <span class="material-icons text-4xl mb-2">local_hospital</span>
          <p class="text-gray-600">Cerca il reparto o l'ambulatorio</p>
        </a>
        <a class="bg-gray-100 p-4 rounded-lg shadow-lg text-center flex flex-col items-center hover:bg-gray-200 transition"
          @click="goToMap">
          <span class="material-icons text-4xl mb-2">map</span>
          <p class="text-gray-600">Visualizza la mappa interattiva</p>
        </a>
        <a class="bg-gray-100 p-4 rounded-lg shadow-lg text-center flex flex-col items-center hover:bg-gray-200 transition"
          @click="goToServices">
          <span class="material-icons text-4xl mb-2">room_service</span>
          <p class="text-gray-600">Scopri i servizi disponibili</p>
        </a>
        <a class="bg-gray-100 p-4 rounded-lg shadow-lg text-center flex flex-col items-center hover:bg-gray-200 transition"
          @click="goToDA">
          <span class="material-icons text-4xl mb-2">support_agent</span>
          <p class="text-gray-600">Assistente digitale</p>
        </a>
      </div>
    </section>

    <!-- Footer -->
    <footer class="bg-gray-800 text-white py-2 fixed bottom-0 w-full">
      <div class="container mx-auto text-center text-sm">
        <p>&copy; 2024 - All Rights Reserved</p>
      </div>
    </footer>
  </div>
</template>

<script>
import 'material-icons/iconfont/material-icons.css';

export default {
  name: 'HomeView',
  data() {
    return {

    };
  },
  methods: {
    goToAR() {
      this.menuOpen = false;
      this.$router.push({ name: 'AR Navigation' });
    },
    goToDA() {
      this.menuOpen = false;
      this.$router.push({ name: 'Digital Assistant' });
    },
    goToLocation() {
      this.menuOpen = false;
      this.$router.push({ name: 'Find Location' });
    },
    goToServices() {
      this.menuOpen = false;
      this.$router.push({ name: 'Services' });
    },
    goToMap() {
      this.menuOpen = false;
      this.$router.push({ name: 'Map' });
    }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 48px;
}
</style>