<template>
  <div>
    <IframeView />
  </div>
</template>

<script>
import IframeView from '@/components/IframeView.vue'

export default {
  name: 'App',
  components: {
    IframeView,
  },
}
</script>

<style>
/* Aggiungi eventuali stili globali qui */
</style>
