<template>
    <li class="p-4 mb-4 border rounded-lg shadow-md">
        <a :href="`/map/${department.id}`"
            class="text-blue-600 hover:underline flex items-center text-xl font-semibold">
            <span class="material-icons mr-2">local_hospital</span>
            {{ department.name }}
        </a>
        <div class="ml-4 text-gray-700 mt-4">
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">person</span>Direttore: {{ department.director }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">place</span>Piano: {{ department.floor }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">phone</span>Telefono: {{ department.phone }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">access_time</span>Orario del passo: {{ department.visitingHours }}</p>
            <p class="flex items-center mb-2"><span class="material-icons icon-small mr-2">group</span>Medici:</p>
            <ul class="ml-8">
                <li v-for="doctor in department.doctors" :key="doctor" class="list-disc">{{ doctor }}</li>
            </ul>
            <button @click="goToMap(department.id)"
                class="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                Vai alla mappa
            </button>
        </div>
    </li>
</template>

<script>
export default {
    name: 'DepartmentCard',
    props: {
        department: {
            type: Object,
            required: true
        }
    },
    methods: {
        goToMap(id) {
            this.$router.push({ name: 'Map', params: { id } });
        }
    }
};
</script>

<style scoped>
span.material-icons.icon-small {
    font-size: 20px !important;
}
</style>