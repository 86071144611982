<template>
  <div>
    <div class="pt-16 px-4 bg-white">
      <div class="my-4">
        <h1 class="text-2xl font-bold mb-4">Servizi</h1>
        <InfoCard v-for="service in services" :key="service.id" :service="service" />
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard.vue';

export default {
  components: {
    InfoCard
  },
  data() {
    return {
      menuOpen: false,
      services: [
        {
          id: 1,
          name: 'Servizio di Accoglienza',
          icon: 'local_hospital',
          description: 'Offriamo assistenza e informazioni per orientarsi all’interno dell’ospedale.',
          hours: '24 ore su 24',
          location: 'Ingresso principale',
          phone: '0123-456789'
        },
        {
          id: 2,
          name: 'Ristorazione',
          icon: 'restaurant',
          description: 'Ristorante e bar con menù vari e opzioni salutari.',
          hours: '08:00 - 20:00',
          location: 'Piano terra, ala est',
          phone: '0123-456790'
        },
        {
          id: 3,
          name: 'Wi-Fi Gratuito',
          icon: 'wifi',
          description: 'Connessione Wi-Fi gratuita in tutto l’ospedale.',
          hours: '24 ore su 24',
          location: 'Ovunque',
          phone: 'N/A'
        },
        {
          id: 4,
          name: 'Parcheggi',
          icon: 'local_parking',
          description: 'Ampio parcheggio per visitatori e pazienti.',
          hours: '24 ore su 24',
          location: 'Vicino all’ingresso principale',
          phone: '0123-456791'
        },
        {
          id: 5,
          name: 'Farmacia',
          icon: 'local_pharmacy',
          description: 'Farmacia interna per tutte le necessità mediche.',
          hours: '09:00 - 18:00',
          location: 'Piano terra, ala ovest',
          phone: '0123-456792'
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<style>
html {
  background-color: white !important;
}
</style>
