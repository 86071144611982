import { createRouter, createWebHistory } from "vue-router";
import HomeView from '@/views/HomeView.vue';
import LoadingView from '@/views/LoadingView.vue';
import DigitalAssistant from '@/views/DigitalAssistant.vue';
import FindLocation from '@/views/FindLocation.vue';
import Services from '@/views/ServicesView.vue';
import Map from '@/views/MapView.vue';
import AR from '@/views/ARView.vue';

const routes = [
    { path: '/', name: 'Loading', component: LoadingView },
    { path: '/home', name: 'Home', component: HomeView },
    { path: '/da', name: 'Digital Assistant', component: DigitalAssistant },
    { path: '/findlocation', name: 'Find Location', component: FindLocation },
    { path: '/services', name: 'Services', component: Services },
    { path: '/map/:id?', name: 'Map', component: Map },
    { path: '/ar', name: 'AR Navigation', component: AR },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
