<template>
  <div>
    <div class="pt-16 px-4 bg-white">
      <div class="my-4">
        <input v-model="searchQuery" type="text" class="w-full p-2 border rounded" placeholder="Cerca il reparto...">
      </div>
      <div v-if="searchResults.length">
        <ul>
          <DepartmentCard v-for="department in searchResults" :key="department.id" :department="department" />
        </ul>
      </div>
      <div v-else>
        <p class="text-gray-600">Nessun reparto trovato.</p>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentCard from '@/components/DepartmentCard.vue';

export default {
  components: {
    DepartmentCard
  },
  data() {
    return {
      searchQuery: '',
      departments: [
        {
          id: 2,
          name: 'Cardiologia',
          director: 'Dr. Mario Rossi',
          floor: '2º',
          phone: '0123-456789',
          visitingHours: '10:00 - 12:00, 16:00 - 18:00',
          doctors: ['Dr. Anna Bianchi', 'Dr. Luigi Verdi']
        },
        {
          id: 1,
          name: 'Oncologia',
          director: 'Dr. Maria Esposito',
          floor: '3º',
          phone: '0123-987654',
          visitingHours: '09:00 - 11:00, 15:00 - 17:00',
          doctors: ['Dr. Paolo Neri', 'Dr. Chiara Russo']
        },
        {
          id: 4,
          name: 'Pediatria',
          director: 'Dr. Giovanni Bruni',
          floor: '1º',
          phone: '0123-456123',
          visitingHours: '10:00 - 12:00, 16:00 - 18:00',
          doctors: ['Dr. Francesca Greco', 'Dr. Matteo Gialli']
        },
        {
          id: 3,
          name: 'Ortopedia',
          director: 'Dr. Lucia Galli',
          floor: '2º',
          phone: '0123-789456',
          visitingHours: '11:00 - 13:00, 17:00 - 19:00',
          doctors: ['Dr. Alessio Viola', 'Dr. Silvia Marini']
        },
        {
          id: 5,
          name: 'Dermatologia',
          director: 'Dr. Marco Sartori',
          floor: '4º',
          phone: '0123-654321',
          visitingHours: '08:00 - 10:00, 14:00 - 16:00',
          doctors: ['Dr. Giulia Fabbri', 'Dr. Andrea Longo']
        },
      ],
    };
  },
  computed: {
    searchResults() {
      return this.departments.filter(department =>
        department.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
  },
};
</script>

<style>
html {
  background-color: white !important;
}
</style>
