<template>
  <div>
    <main>
      <MenuHeader />
      <router-view></router-view>
    </main>

    <footer>
    </footer>
  </div>
</template>

<script>
import MenuHeader from './components/MenuHeader.vue';

export default {
  components: {
    MenuHeader
  },
  name: 'routAR',
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 48
}
</style>