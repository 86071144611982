<template>
    <header class="bg-blue-600 text-white flex justify-between items-center fixed w-full top-0">
        <div class="relative" style="padding-left: 10px; padding-top: 5px;">
            <button @click="toggleMenu" class="text-white">
                <span class="material-icons" style="font-size: 30px">menu</span>
            </button>
            <div v-if="menuOpen" class="absolute bg-white text-black mt-2 py-2 w-48 rounded-lg shadow-lg z-10">
                <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-200 text-lg" @click="goToHome">
                    <span class="material-icons icon-small" style="font-size: 30px">home</span>
                    <span class="ml-2">Home</span>
                </a>
                <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-200 text-lg" @click="goToLocation">
                    <span class="material-icons icon-small" style="font-size: 30px">meeting_room</span>
                    <span class="ml-2">Reparti</span>
                </a>
                <a href="#map" class="flex items-center px-4 py-2 hover:bg-gray-200 text-lg" @click="goToMap">
                    <span class="material-icons icon-small" style="font-size: 30px">map</span>
                    <span class="ml-2">Mappa</span>
                </a>
                <a href="#services" class="flex items-center px-4 py-2 hover:bg-gray-200 text-lg" @click="goToServices">
                    <span class="material-icons icon-small" style="font-size: 30px">medical_information</span>
                    <span class="ml-2">Servizi</span>
                </a>
                <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-200 text-lg" @click="goToDA">
                    <span class="material-icons icon-small" style="font-size: 30px">support_agent</span>
                    <span class="ml-2">Supporto</span>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import 'material-icons/iconfont/material-icons.css';

export default {
    data() {
        return {
            menuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        goToHome() {
            this.menuOpen = false;
            this.$router.push({ name: 'Home' });
        },
        goToDA() {
            this.menuOpen = false;
            this.$router.push({ name: 'Digital Assistant' });
        },
        goToLocation() {
            this.menuOpen = false;
            this.$router.push({ name: 'Find Location' });
        },
        goToServices() {
            this.menuOpen = false;
            this.$router.push({ name: 'Services' });
        },
        goToMap() {
            this.menuOpen = false;
            this.$router.push({ name: 'Map' });
        }
    }
};
</script>

<style scoped>
span.material-icons.icon-small {
    font-size: 20px !important;
}

header {
    position: fixed;
    top: 0;
    z-index: 1000;
}
</style>