<!-- src/components/LoadingView.vue -->
<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-100 to-blue-300">
        <img src="@/assets/android-chrome-512x512.png" alt="Loading Image" class="w-1/2 max-w-xs animate-bounce">
    </div>
</template>

<script>
export default {
    name: 'LoadingView',
    mounted() {
        setTimeout(() => {
            this.$router.push({ name: 'Home' });
        }, 4000);
    }
};
</script>

<style scoped>
/* Tailwind CSS classes are used direttamente nel template */
</style>