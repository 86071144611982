<template>
    <div class="flex flex-col bg-gray-100" style="height: 100dvh;">

        <!-- Chat Messages -->
        <div class="flex-1 overflow-y-auto p-4 mt-8">
            <div v-for="message in messages" :key="message.id" class="mb-4">
                <div v-if="message.sender === 'assistant'" class="text-left">
                    <div class="inline-block bg-gray-200 text-gray-800 p-2 rounded-lg">
                        {{ message.text }}
                    </div>
                </div>
                <div v-else class="text-right">
                    <div class="inline-block bg-blue-500 text-white p-2 rounded-lg">
                        {{ message.text }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Input Box -->
        <div class="bg-white p-4 border-t border-gray-300">
            <div class="flex">
                <input type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="la tua richiesta..."
                    class="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring" />
                <button @click="sendMessage" class="ml-2 bg-blue-600 text-white px-4 py-2 rounded-lg">
                    Invia
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            messages: [
                { id: 1, text: 'Ciao, come posso aiutarti?', sender: 'assistant' },
            ],
            newMessage: '',
        };
    },
    methods: {
        sendMessage() {
            if (this.newMessage.trim() !== '') {
                this.messages.push({
                    id: Date.now(),
                    text: this.newMessage,
                    sender: 'user',
                });
                this.newMessage = '';

                setTimeout(() => {
                    this.messages.push({
                        id: Date.now(),
                        text: '...risposta dell\'assistente...',
                        sender: 'assistant',
                    });
                }, 1000);
            }
        },
    },
};
</script>

<style scoped>
/* Optional custom styles */
</style>